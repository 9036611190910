.h1-Pollaroid{
  font-family: Pollaroid;
  font-size: 140px;
  margin-bottom: -30px;
  color: #ff732d;
}
.h1-Pollaroid-span{
  margin-bottom: -100px !important;
  color: #ff732d;
}

.banner-1{
  background: #FEF8EF;
  min-height: 91vh;
  .container{
    .row{
      .col-lg-6{
        .h1-Pollaroid{
          font-family: Pollaroid;
          font-size: 140px;
          margin-bottom: -40px;
          color: #ff732d;
        }
      }
    }
  }
}
.div-shops{
  background: #fffcf8;
  padding-top: 100px;
  padding-bottom: 100px;
}
.div-vantagem{
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #fff;
  .container{
    .row{
      .col-md-4{
        .div-icon{
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
.banner2{
  background: #FEF8EF;
  padding-top: 100px;
}
.rodape{
  padding-top: 50px;
  padding-bottom: 20px;
  color: #fff !important;
  .container{
    hr{
      opacity: 0.1;
    }
    .row{
      .col-lg-2{
        .nav-link-inc{
          transition:0.2s;
          background: rgb(54, 54, 54);
          height: 30px;
          width: 30px;
          color: #e4e4e4;
          padding: 10px;
        
        }
        .nav-link-inc:hover{
          transition:0.2s;
          background: rgb(247,203,107);
          background: linear-gradient(324deg, rgba(247,203,107,1) 18%, rgba(255,115,45,1) 100%);
          height: 30px;
          width: 30px;
          color: #ffffff;
          padding: 10px;
        
        }
      }
    }
  }
}