.btn-drop{
  transition: 0.3s;
  border: none !important;
  background: none !important;
  outline: none !important;
  color: #111 !important;
  box-shadow: none !important;
  font-size: 18px !important;
}
.btn-drop:hover{
  transition: 0.3s;
  border: none !important;
  background: none !important;
  outline: none !important;
  color: #cc9900 !important;
  box-shadow: none !important;
  font-size: 18px !important;
}
.input-group{
  .input-group-text{
    background: #fff !important;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    color: rgb(112, 112, 112);
  }
  input{
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
select{
  border: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  outline: none !important;
}
.width100{
  width: 100%;
}
.text-right{
  text-align: right;
}
.card-table{
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.027) !important;
  max-height: 70vh;
  min-height: 70vh;
  overflow: auto;
  .card-body{
    table{
      font-size: 14px;
      th{
        color: #767681;
      }
      td{
        padding-top: 15px;
      }
      tr{
        height: 55px !important;
      }
      tbody{
        border-top: 1px !important;
      }
    }
  }
}

.page-link{
border-radius: 50px !important;
margin: 4px !important;
border: none !important;
color: #111 !important;
box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.164) !important;
}
.page-link.active{
background: #111;
color: #fff !important;
}
.page-link:hover{
background: #111 !important;
color: #fff !important;
}
.bg-warning-tb{
  background: #ffc10727 !important;
  color: #ffc107 !important;
}
.bg-success-tb{
  background: #1987543a !important;
  color: #198754 !important;
}
.bg-primary-tb{
  background: #0d6dfd3c !important;
  color: #0d6efd !important;
}
.bg-danger-tb{
  background: #dc35461f !important;
  color: #dc3545 !important;
}