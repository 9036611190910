.progre-entre{
  background: #fff;
  width: 70px;
  height: 70px;
  padding: 5px;
  border-radius: 50px;
  z-index: 2;
}
.progre-entre.active{
  background: #fec74f !important;
}
.progress{
  z-index: 1;
  margin-top: -43px;
}
.progress-bar{
  background: #fec74f !important;
  width: 100%;
}