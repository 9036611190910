.h1-400{
  font-size: 300px;
  color: #eaa819;
}
.height-100{
  
  height: 100vh;
}
.width-100{
  width: 100%;
}