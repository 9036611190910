.body{
  background: #f8f9fa !important;
  min-height: 102vh;
}
.bg-card{
  padding: 7px;
  border-radius: 50px;
}
.card-list{
  border-radius: 15px !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.027) !important;
  border: none !important;
}
.btn-off-menu{
  background: none !important;
  border: none !important;
  color: #fff;
}