.main{
  .container-fluid{
    .link-text{
      text-decoration: none;
      color: #111;
    }
    .separator{
      font-size: 14px;
      color: #bcbcbc;
      margin: 0px 0;
      display: flex;
      align-items: center;

      &::before{
        content: '';
        flex: 1;
        height: 1px;
        background: #bcbcbcb8;
        margin-right: 16px;
      }

      &::after{
        content: '';
        flex: 1;
        height: 1px;
        background: #bcbcbcb8;
        margin-left: 16px;
      }
    }
    .linkes{
      .btn-light{
        height: 50px;
        width: 50px;
        background: #fff;
        padding: 0px !important;
        img{
          width: 25px;
        }
      }
      .btn-primary{
        height: 50px;
        width: 50px;
        background: #0d6efd;
        color: #fff !important;
        padding: 0px !important;
        i{
          font-size: 20px;
          margin-top: 5px;
        }
      }
      .btn-dark{
        height: 50px;
        width: 50px;
        padding: 0px !important;
        i{
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }
    .text-pergunta{
      .a{
        transition: 0.2s;
        text-decoration: none;
        color: #eaa819;
      }
      .a:hover{
        transition: 0.2s;
        color: #805700;
      }
    }
    .background-auth{
      background: #ecb46519;
      .container{
        text-align: center;
        img{
          width: 570px;
          margin-bottom: 20px;
        }
        .text-background{

        }
      }
    }
  }
}